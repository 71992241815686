import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
import { Trans } from 'next-i18next'
import Button from '../button/Button'
import Modal, { ModalProps } from '../modal/Modal'
import ModalActions from '../modal/ModalActions'
import ModalIcon from '../modal/ModalIcon'
import ModalText from '../modal/ModalText'
import ModalTitle from '../modal/ModalTitle'
import React, { ReactNode, useState } from 'react'

export type ConfirmEvent = {
    preventClose: () => void
    close: () => void
}

type ConfirmDialogPropsChildren<D = unknown> = (
    open: (data?: D) => void,
    close: () => void,
    isOpened: boolean
) => ReactNode
type ConfirmDialogPropsOnConfirm<D = unknown> = (data: D | undefined, event: ConfirmEvent) => void

export type ConfirmDialogProps<D = unknown> = Omit<ModalProps, 'show'> & {
    text: ReactNode
    subtitle?: ReactNode
    onCancel?: () => void
    onConfirm?: ConfirmDialogPropsOnConfirm<D>
    onConfirmSecond?: ConfirmDialogPropsOnConfirm<D>
    cancelLabel?: string | ReactNode
    disabledCancelButton?: boolean
    okLabel?: string | ReactNode
    secondOkLabel?: string | ReactNode
    loadingOkButton?: boolean
    loadingSecondOkButton?: boolean
    children: ConfirmDialogPropsChildren<D>
    onlyOkButton?: boolean
    icon?: ReactNode
    renderXIcon?: boolean
}

const ConfirmDialog = <D extends unknown = unknown>({
    children,
    text,
    icon,
    subtitle,
    onConfirm,
    onConfirmSecond,
    onCancel,
    cancelLabel,
    okLabel,
    secondOkLabel,
    loadingOkButton,
    loadingSecondOkButton,
    disabledCancelButton,
    color = 'primary',
    onlyOkButton,
    renderXIcon,
    width = 460,
    ...props
}: ConfirmDialogProps<D>) => {
    const [show, setShow] = useState<boolean>(false)
    const [data, setData] = useState<D>()

    const open = (data?: D) => {
        setData(data)
        setShow(true)
    }
    const close = () => {
        setData(undefined)
        setShow(false)
    }

    const handleConfirm = (onConfirmHandler?: ConfirmDialogPropsOnConfirm<D>) => {
        let preventClose = false
        const confirmEvent: ConfirmEvent = {
            preventClose: () => (preventClose = true),
            close
        }
        onConfirmHandler?.(data, confirmEvent)
        if (!preventClose) {
            close()
        }
    }

    const cancel = () => {
        onCancel?.()
        close()
    }

    return (
        <>
            <Modal
                {...props}
                color={color}
                show={show}
                width={width}
                shouldFocusAfterRender={false}
                onClose={cancel}
                renderXIcon={renderXIcon}
            >
                <ModalIcon color='custom'>{icon || <QuestionMarkCircleIcon />}</ModalIcon>
                <ModalTitle>{text}</ModalTitle>
                {subtitle && <ModalText>{subtitle}</ModalText>}
                <ModalActions className='flex-wrap'>
                    {!onlyOkButton && (
                        <Button
                            className='w-full my-1'
                            onClick={() => handleConfirm(onConfirm)}
                            loading={loadingOkButton}
                        >
                            {okLabel || <Trans i18nKey='shared.confirm_dialog.button.ok' />}
                        </Button>
                    )}
                    {!onlyOkButton && onConfirmSecond && (
                        <Button
                            className='w-full my-1'
                            onClick={() => handleConfirm(onConfirmSecond)}
                            loading={loadingSecondOkButton}
                        >
                            {secondOkLabel || <Trans i18nKey='shared.confirm_dialog.button.second_ok' />}
                        </Button>
                    )}
                    {onlyOkButton && (
                        <Button
                            className='w-full my-1'
                            variant='destructive'
                            onClick={cancel}
                            disabled={disabledCancelButton}
                        >
                            {cancelLabel || <Trans i18nKey='shared.confirm_dialog.button.cancel' />}
                        </Button>
                    )}
                </ModalActions>
            </Modal>
            {children(open, close, show)}
        </>
    )
}

export default ConfirmDialog
