import { Dialog, Transition } from '@headlessui/react'
import { ModalActionsProps } from './ModalActions'
import { ModalIconProps } from './ModalIcon'
import { ModalTextProps } from './ModalText'
import { ModalTitleProps } from './ModalTitle'
import { ThemeProps } from '../theme/ThemeProps'
import { XIcon } from '@heroicons/react/outline'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import { findChildrenElement } from '../../../utils/Helper'
import React, { ElementType, Fragment, HTMLAttributes, PropsWithChildren } from 'react'
import usePortal from 'react-useportal'

type ModalVariant = 'centered' | 'simple'

export type ModalProps<P = any> = HTMLAttributes<HTMLDivElement> &
    ThemeProps &
    Partial<P> & {
        show?: boolean
        onClose?: (value: boolean) => void
        variant?: ModalVariant
        as?: ElementType<P>
        modalContainerClassName?: string
        width?: number | string
        disableMaxWidth?: boolean
        renderXIcon?: boolean
    }

const Modal = ({
    children,
    show = false,
    onClose,
    variant = 'centered',
    color = 'primary',
    className,
    modalContainerClassName,
    width = 'fit-content',
    disableMaxWidth = false,
    renderXIcon = false,
    as = 'div',
    ...props
}: PropsWithChildren<ModalProps>) => {
    const { Portal } = usePortal()

    const renderIcon = () => {
        const icon = findChildrenElement<ModalIconProps>(children, 'ModalIcon')
        if (!icon) {
            return
        }
        return React.cloneElement(icon, {
            color: icon.props.color || color,
            ...icon.props
        })
    }

    const render = <P,>(name: 'ModalTitle' | 'ModalText' | 'ModalActions') => {
        return findChildrenElement<P>(children, name)
    }

    return (
        <Portal>
            <Transition.Root show={show}>
                <Dialog
                    as={as}
                    {...props}
                    className={buildClassesWithDefault(
                        {
                            modal: true,
                            [color]: true,
                            [`${variant}-variant`]: true
                        },
                        className
                    )}
                    onClose={value => {
                        return onClose && onClose(value)
                    }}
                >
                    <div className='wrapper'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0'
                            enterTo='opacity-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <Dialog.Overlay className='modal-overlay' />
                        </Transition.Child>
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >
                            <div
                                className={buildClassesWithDefault('modal-container relative', modalContainerClassName)}
                                style={{ maxWidth: width }}
                            >
                                {renderXIcon && (
                                    <XIcon
                                        height={24}
                                        className='absolute top-2 right-2 cursor-pointer text-gray-450'
                                        onClick={() => onClose?.(false)}
                                    />
                                )}
                                <div
                                    className={buildClassesWithDefault(
                                        { 'sm:max-w-3xl': !disableMaxWidth },
                                        'modal-container-wrapper'
                                    )}
                                >
                                    {renderIcon()}
                                    <div className='modal-content'>
                                        <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                                            {render<ModalTitleProps>('ModalTitle')}
                                        </Dialog.Title>
                                        <div className='mt-2'>{render<ModalTextProps>('ModalText')}</div>
                                    </div>
                                </div>
                                {render<ModalActionsProps>('ModalActions')}
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </Portal>
    )
}

Modal.displayName = 'Modal'

export default Modal
